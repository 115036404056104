<script setup lang="ts">
import {ref, onMounted, onBeforeUnmount} from 'vue'
import {getTranslation} from '@/ts/utilities.ts'
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import { CarouselData } from '@/interfaces/ProductViewInterface.ts'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Cylindo from '@/ts/CustomBindings/Cylindo.ts'
import Splash from '@/components/SplashElement.vue'
import VideoBlock from "@/components/blocks/VideoBlock.vue";

interface Props {
	carouselData: Object
}

const props = defineProps<Props>()
const carousel = ref<CarouselData>(props.carouselData)


const mainOptions = {
	rewind: true,
	type      : 'loop',
	perPage   : 1,
	perMove   : 1,
	gap       : '1rem',
	pagination: false,
	arrows: true,
	drag: false,
	dragAngleThreshold: 0,
	classes: {
		prev  : 'splide__arrow--prev bg-white',
		next  : 'splide__arrow--next bg-white',
	}
}

const thumbsOptions = {
	type        : 'slide',
	rewind      : true,
	gap         : '',
	pagination  : false,
	fixedWidth  : '100%',
	fixedHeight : '100px',
	focus       : 'center',
	isNavigation: true,
	updateOnMove: true,
	direction: 'ttb',
	height   : '100%',
	classes: {
		prev  : 'splide__arrow--prev bg-white',
		next  : 'splide__arrow--next bg-white',
	}
}


//ELEMENTS
let thumbssplideref = ref(null)
let mainsplideref = ref(null)
let productcarouselref = ref(null)
let productcarouselchildelementref = ref(null)

if(!window['co3VueProductCarousel']) {
	window['co3VueProductCarousel'] = [ carousel ]
} else {
	window['co3VueProductCarousel'].push(carousel)
}

let carouselIsExpanded = ref(false)
let carouselHasPages = false
let isMobileSize = false
let hasVerticalNavigation = true
let renderedThumbsOnce = false
let cylindoFeatures = ref(carousel?.value.cylindo?.defaultFeatures)
let cylindoImages = ref(carousel?.value.media?.cylindoImages)

const isCylindoLoaded = (() => {
	return customElements.whenDefined( 'cylindo-viewer' )
})()

const handleCarouselPadding = () => {
	if(isMobile.matches) {
		productcarouselref.value.style.padding = '1rem 1rem'
	} else {
		productcarouselref.value.style.padding = '4% 8%'
	}
}

const SetMobileRestrictions = () => {
	isMobileSize = true
	CloseCarousel()
	Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
		element.classList.remove('pointer-events-none')
	})
	RenderThumbs()
}

const RemoveMobileRestrictions = () => {
	isMobileSize = false
	Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
		element.classList.add('pointer-events-none')
	})
	RenderThumbs()
}

const ExpandCarousel = (e) => {
	if(e) {
		e.stopPropagation()
	}
	if(!isMobileSize) {
		productcarouselref.value.style.backgroundColor = 'rgba(0,0,0,0.5)'
		carouselIsExpanded.value = true
		let closeButton = productcarouselref.value.querySelector('.js-modal-closer')
		if(closeButton) {
			closeButton.classList.remove('hidden')
		}

		Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
			element.classList.remove('pointer-events-none')
		})
		productcarouselref.value.classList.add('is-expanded')
		productcarouselref.value.style.zIndex = '40'
		productcarouselref.value.style.position = 'fixed'
		handleCarouselPadding()
		productcarouselchildelementref.value.style.padding = '3em 1.5em'
		//mainsplideref.value.go(mainsplideref.value.index);

		let splash = productcarouselref.value.querySelector('.product-card-splash')
		if(splash) {
			splash.style.display = 'none'
		}
	}


	mainsplideref.value.splide.event.emit('resize')
	thumbssplideref.value.splide.event.emit('resize')
}

const CloseCarousel = (e) => {
	if(e) {
		e.stopPropagation()
	}
	carouselIsExpanded.value = false
	let closeButton = productcarouselref.value.querySelector('.js-modal-closer')
	if(closeButton) {
		closeButton.classList.add('hidden')
	}
	Array.from(productcarouselref.value.querySelectorAll('.js-pointer-events-toggle')).forEach((element, index) => {
		element.classList.add('pointer-events-none')
	})
	productcarouselref.value.classList.remove('is-expanded')
	productcarouselref.value.style.zIndex = 'inherit'
	productcarouselref.value.style.position = 'inherit'
	productcarouselref.value.style.padding = '0'
	productcarouselchildelementref.value.style.border = '0'
	productcarouselchildelementref.value.style.padding = '0'

	let splash = productcarouselref.value.querySelector('.product-card-splash')
	if(splash) {
		splash.style.display = ''
	}

	mainsplideref.value.splide.event.emit('resize')
	thumbssplideref.value.splide.event.emit('resize')
}

const RenderVerticalThumbs = () => {
	hasVerticalNavigation = true
	thumbssplideref.value.splide.options = {
		direction: 'ttb',
		fixedWidth  : '100%',
		fixedHeight : '20%',
	}
	mainsplideref.value.splide.options = {
		arrows: true,
	}
	thumbssplideref.value.root.classList.remove('w-full')
	thumbssplideref.value.root.classList.remove('h-1/6')

	thumbssplideref.value.root.classList.add('h-full')
	thumbssplideref.value.root.classList.add('w-2/12')
	thumbssplideref.value.root.classList.add('absolute')

	mainsplideref.value.root.classList.add('w-9/12')
	mainsplideref.value.root.classList.add('h-full')
	mainsplideref.value.root.classList.remove('w-full')
	mainsplideref.value.root.classList.remove('h-5/6')

	let container = productcarouselchildelementref.value.querySelector('.js-splide-direction-container')
	container.classList.remove('flex')
	container.classList.remove('flex-col-reverse')

	mainsplideref.value.splide.event.emit('resize')
	thumbssplideref.value.splide.event.emit('resize')
	renderedThumbsOnce = true
}

const RenderHorisontalThumbs = () => {
	hasVerticalNavigation = false
	thumbssplideref.value.splide.options = {
		direction: 'ltr',
		fixedWidth: '25%',
		fixedHeight: '100%',
	}
	mainsplideref.value.splide.options = {
		arrows: false,
	}

	thumbssplideref.value.root.classList.add('w-full')
	thumbssplideref.value.root.classList.add('h-1/6')

	thumbssplideref.value.root.classList.remove('h-full')
	thumbssplideref.value.root.classList.remove('w-2/12')
	thumbssplideref.value.root.classList.remove('absolute')

	mainsplideref.value.root.classList.remove('w-9/12')
	mainsplideref.value.root.classList.remove('h-full')
	mainsplideref.value.root.classList.add('w-full')
	mainsplideref.value.root.classList.add('h-5/6')

	let container = productcarouselchildelementref.value.querySelector('.js-splide-direction-container')
	container.classList.add('flex')
	container.classList.add('flex-col-reverse')

	mainsplideref.value.splide.event.emit('resize')
	thumbssplideref.value.splide.event.emit('resize')
	renderedThumbsOnce = true
}

const RenderThumbs = () => {
	if(carouselHasPages) {
		if (productcarouselref.value.clientWidth > productcarouselref.value.clientHeight) {
			if (hasVerticalNavigation === false || renderedThumbsOnce === false) {
				RenderVerticalThumbs()
			}
		} else if (hasVerticalNavigation === true || renderedThumbsOnce === false) {
			RenderHorisontalThumbs()
		}
	}
}

const StopPropagation = (e) => {
	e.stopPropagation()
}
const keydownHandler = (e) => {
	if (e.key === 'Escape') {
		CloseCarousel(e)
	}
}

const checkSplideLength = () => {
	if ( mainsplideref.value.splide.length <= mainsplideref.value.splide.options.perPage ) {
		carouselHasPages = false
		renderedThumbsOnce = false
		//HIDE THUMBS IF ONLY 1 PAGE
		thumbssplideref.value.root.classList.remove('block')
		thumbssplideref.value.root.classList.add('hidden')

		//SET MAIN SPLIDE TO OCCUPY FULL AREA
		mainsplideref.value.root.classList.remove('w-9/12')
		mainsplideref.value.root.classList.remove('h-5/6')
		mainsplideref.value.root.classList.add('h-full')
		mainsplideref.value.root.classList.add('w-full')

		mainsplideref.value.splide.options = {
			arrows: false,
		}
		carouselHasPages = false
	} else {
		carouselHasPages = true
		//SHOW THUMBS
		thumbssplideref.value.root.classList.add('block', 'mt-[48px]', 'md:mt-0')
		thumbssplideref.value.root.classList.remove('hidden')

		RenderThumbs()

		mainsplideref.value.splide.options = {
			arrows: true,
		}
		carouselHasPages = true
	}
}

// Create a condition that targets viewports at least 768px wide
const isMobile = window.matchMedia('(max-width: 1023px)')

const handleMobileDesktopChange = (e) => {
	// Check if the media query is true
	if(carouselIsExpanded.value) {
		handleCarouselPadding()
	}
	if (e.matches) {
		SetMobileRestrictions()
	} else {
		RemoveMobileRestrictions()
	}
}

// Register event listener
isMobile.addEventListener('change', handleMobileDesktopChange)
window.addEventListener('resize', (e) => {
	RenderThumbs()
})

onBeforeUnmount(() => {
	window.removeEventListener('keydown', keydownHandler)
})


onMounted(async () => {
	window.addEventListener('keydown', keydownHandler)

	productcarouselref.value.style.backgroundColor = 'rgba(0,0,0,0.5)'

	Array.from(productcarouselref.value.querySelectorAll('.splide__track')).forEach((element, index) => {
		element.classList.add('h-full')
	})

	if(mainsplideref.value) {
		if ( thumbssplideref.value.splide ) {
			mainsplideref.value.sync(thumbssplideref.value.splide)
		}

		mainsplideref.value.splide.on('refresh', () => {
			checkSplideLength()
			handleMobileDesktopChange(isMobile)
			mainsplideref.value.splide.event.emit('resize')
			thumbssplideref.value.splide.event.emit('resize')
		})

		// Initial check
		checkSplideLength()
		handleMobileDesktopChange(isMobile)
	}

	Object.assign(Cylindo.selectedCylindoFeatures, Cylindo.convertCylindoFeatureArrayToObject(carousel.value?.cylindo?.defaultFeatures.split('|')))
	Cylindo.setFeatures()
})

</script>

<template>
	<div class="product-splide-carousel-carousel">
		<div class="flex relative w-full h-full top-0 bottom-0 left-0 right-0"
			 ref="productcarouselref"
			 @click="CloseCarousel">
			<div class="w-full h-full relative bg-white"
				 @click="StopPropagation"
				 ref="productcarouselchildelementref">

				<div
					@click="CloseCarousel" style="z-index:50;margin:0.5em 1em;" class="absolute right-0 top-0 hidden p-1.5 cursor-pointer js-modal-closer">
					<span class="mr-2">{{ getTranslation('Close') }}</span>
					<font-awesome-icon :icon="['fal', 'close']" />
				</div>

				<div class="js-splide-direction-container w-full h-full relative">
					<Splide class="splide block absolute top-0 bottom-0 mobile-thumb-height"
							:options="thumbsOptions"
							:has-track="false"
							ref="thumbssplideref"
					>
						<SplideTrack>
							<SplideSlide v-if="carousel?.cylindo?.isUsingCylindoAssets">
								<div class="w-full h-full flex items-center">
									<img
										class="w-full h-full object-cover js-cylindo-carousel-image"
										width="320" height="180"
										alt=""
										:src="`${carousel?.media.productImage}&size=(320,180)`"
									/>
								</div>
							</SplideSlide>
							<SplideSlide v-for="image in carousel?.media?.images">
								<div class="w-full h-full flex items-center">
									<img class="w-full h-full object-cover" width="320" height="180" :src="'/Admin/Public/GetImage.ashx?Image=' + image.url + '&Width=320&height=180&Format=webP&Quality=90&Crop=5&resolution=50'"
										 alt="">
								</div>
							</SplideSlide>
							<SplideSlide v-for="image in carousel?.media?.moodImages">
								<div class="w-full h-full flex items-center">
									<img class="w-full h-full object-cover" width="320" height="180" :src="'/Admin/Public/GetImage.ashx?Image=' + image.url + '&Width=320&height=180&Format=webP&Quality=90&Crop=5&resolution=50'"
										 alt="">
								</div>
							</SplideSlide>
							<SplideSlide v-for="video in carousel?.media?.videos">
								<div class="flex items-center justify-center h-full">
									<font-awesome-icon
										:icon="['fal', 'play-circle']"
										class="text-4xl text-white relative z-30"
									/>
									<span class="bg-black/25 absolute w-full h-full z-20"></span>
									<img class="object-cover" width="320" height="180"
										 :src="`https://i3.ytimg.com/vi/${video.videoId}/hqdefault.jpg`"
										 alt="" />
								</div>
							</SplideSlide>
							<SplideSlide v-if="carousel?.cylindo?.isUsingCylindoAssets" v-for="image in cylindoImages">
								<div class="w-full h-full flex items-center">
									<img class="w-full h-full object-cover js-cylindo-carousel-image" width="320" height="180" :src="image.url"
										 alt="">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carousel?.cylindo?.isUsingCylindoAssets && carousel?.cylindo?.dimensionShotCode">
								<div class="w-full h-full flex items-center">
									<img class="w-full h-full object-cover js-cylindo-carousel-image" width="320" height="180"
										 :src="Cylindo.getDimensionShot(carousel.cylindo.feature, carousel.cylindo.id, carousel.cylindo.dimensionShotCode, 320)"
										 alt="">
								</div>
							</SplideSlide>
						</SplideTrack>
						<div class="splide__arrows">
							<button class="splide__arrow splide__arrow--prev bg-white"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
							<button class="splide__arrow splide__arrow--next bg-white"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
						</div>
					</Splide>

					<Splide class="splide ml-auto"
							:options="mainOptions"
							ref="mainsplideref"
							:has-track="false"
					>
						<SplideTrack>
							<SplideSlide v-if="carousel?.cylindo?.isUsingCylindoAssets"
										 class="js-cylindo-main-container-target-container" v-on:click="ExpandCarousel">
								<div class="w-full h-full flex pointer-events-none js-pointer-events-toggle relative">
									<span
										class="relative w-full h-full"
										v-html="Cylindo.renderCylindoElement(carousel.cylindo, 'cylindo-360', {id: 'cylindo-main-container',controls: 'zoom'})"></span>
								</div>
							</SplideSlide>
							<SplideSlide v-for="image in carousel?.media?.images"
										 v-on:click="ExpandCarousel">
								<div class="w-full h-full flex items-center pointer-events-none js-pointer-events-toggle">
									<img class="w-full h-full object-contain" width="800" height="600" :src="'/Admin/Public/GetImage.ashx?Image=' + image.url + '&Width=800&height=600&Format=webP&Quality=90&Crop=5&resolution=50'"
										 alt="">
								</div>
							</SplideSlide>
							<SplideSlide v-for="image in carousel?.media?.moodImages"
										 v-on:click="ExpandCarousel">
								<div class="w-full h-full flex items-center pointer-events-none js-pointer-events-toggle">
									<img class="w-full h-full object-contain" width="800" height="600" :src="'/Admin/Public/GetImage.ashx?Image=' + image.url.image + '&Width=800&height=600&Format=webP&Quality=90&Crop=5&resolution=50'"
										 alt="">
								</div>
							</SplideSlide>
							<SplideSlide
									v-for="(video, index) in carousel?.media?.videos"
									:key="index"
									 @click="ExpandCarousel"
								>
								<VideoBlock
									:ref="'videoElement' + index"
									:video="video.videoId"
									context="carousel"
								/>
							</SplideSlide>
							<SplideSlide v-if="carousel?.cylindo?.isUsingCylindoAssets" v-for="image in cylindoImages"
										 v-on:click="ExpandCarousel" data-type="cylindo">
								<div class="w-full h-full flex items-center pointer-events-none js-pointer-events-toggle">
									<img class="w-full h-full object-contain js-cylindo-carousel-image" width="800" height="600" :src="image.url"
										 alt="">
								</div>
							</SplideSlide>
							<SplideSlide v-if="carousel?.cylindo?.isUsingCylindoAssets && carousel?.cylindo?.dimensionShotCode"
										 v-on:click="ExpandCarousel">
								<div class="w-full h-full flex pointer-events-none js-pointer-events-toggle">
									<img class="w-full h-full object-contain js-cylindo-carousel-image" width="800" height="600"
										 :src="Cylindo.getDimensionShot(carousel.cylindo.feature, carousel.cylindo.id, carousel.cylindo.dimensionShotCode, 1100)"
										 alt="">
								</div>
							</SplideSlide>
						</SplideTrack>
						<div class="splide__arrows">
							<button class="splide__arrow splide__arrow--prev bg-white"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
							<button class="splide__arrow splide__arrow--next bg-white"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
						</div>
					</Splide>

					<Splash
						v-if="carousel?.productSplash?.visible"
						:type="carousel?.productSplash?.typeId"
					/>
				</div>
			</div>
		</div>
		<div class="hidden xl:flex justify-end gap-4 pt-5">
			<!--							<button class="hover:tw-underline" v-if="carouselProduct.videos.length > 0" @click="OpenVideo">-->
			<!--								<font-awesome-icon icon="play"/>-->
			<!--								<span class="tw-ml-2">Video</span>-->
			<!--							</button>-->
			<button v-if="!carouselIsExpanded" class="hover:underline z-20" @click="ExpandCarousel">
				<font-awesome-icon :icon="['fal', 'magnifying-glass']" />
				<span class="ml-2">Zoom</span>
			</button>
		</div>
	</div>
</template>

<style>
.product-splide-carousel-carousel {
	@apply h-[30rem] md:max-h-dvh lg:max-h-dvh;
}
.product-splide-carousel-carousel cylindo-viewer,
.product-splide-carousel-carousel img {
	@apply absolute h-full top-0;
}

.splide__track--nav > .splide__list {
	@apply flex md:flex-col gap-4;
}
.splide__track--nav > .splide__list > .splide__slide {
	@apply border border-grey rounded-sm overflow-hidden;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
	@apply border border-black;
}

</style>
