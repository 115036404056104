﻿<script setup lang="ts">
import {computed, onMounted, ref} from 'vue'
import {getTranslation} from '../ts/utilities.ts'
import Spinner from '@/components/ui/decoration/Spinner.vue'
import RenderAction from "@/components/helpers/RenderAction.vue";

interface Props {
	endpoint: string,
	filters: []
}

const props = defineProps<Props>()


const selectedFilter = ref('')
const brandData = ref('')
const isLoading = ref(false)
const setFilter = (filter: string) =>{
	selectedFilter.value = filter
	getData()
}

const getData = async() =>{
	isLoading.value = true
	const request = await fetch(`${props.endpoint}?filter=${selectedFilter.value}`)
	brandData.value = await request.json()
	isLoading.value = false
}

const goToLink = (url) =>{
	location.href = url
}

onMounted(async () =>{
	await getData()
})

</script>

<template>
	<div class="container mx-auto px-4 py-8">
		<div class="flex justify-center flex-wrap">
			<div class="category-filters py-2 px-5 rounded pointer mx-2 mb-3"
				 :class="{selected: selectedFilter === ''}"
				 v-on:click="setFilter('')">{{getTranslation('AllCategories')}}</div>
			<div v-for="filter in filters" :key="filter.$id"
				 class="category-filters py-2 px-5 rounded pointer mx-2 mb-3"
				 :class="{selected: selectedFilter === filter}"
				 v-on:click="setFilter(filter)">{{filter}}</div>
		</div>
	</div>

	<Spinner v-if="isLoading" class="container">
		{{ getTranslation('FilteringBrands') }}
	</Spinner>
	<div v-else class="container mx-auto px-4 py-8" v-if="brandData.length">
		<div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
			<div class="w-full mb-4 cursor-pointer brand" v-on:click="goToLink(brand.url)" v-for="brand in brandData" :key="brand.$id">
			<div class="flex flex-col justify-center p-3 aspect-[4/3] shadow-lg">
				<div class="my-1">
					<img class="img-fluid mx-auto d-block" :src="'/Admin/Public/GetImage.ashx?Image=' + brand.logo + '&Width=210&height=120&Format=png&Crop=5&resolution=90'" />
				</div>
				<RenderAction
					action="link"
					theme="primary"
					size="sm"
					class="mx-auto"
					@click="goToLink(brand.url)"
				>
					{{getTranslation('BrandListReadMore')}}
				</RenderAction>
			</div>

		</div>

		</div>
	</div>
</template>
