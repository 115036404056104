<script setup lang="ts">
import {onMounted, ref} from 'vue'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { getTranslation } from '@/ts/utilities.ts'
import RenderAction from '@/components/helpers/RenderAction.vue'
import Splash from '@/components/SplashElement.vue'
import PriceElement from '@/components/PriceElement.vue'

import { ProductData } from '@/interfaces/ProductInterface.ts'

interface Props {
	product: ProductData,
}
const props = defineProps<Props>()
const product: ProductData = props.product

let productCardTranslations = ref()

productCardTranslations.value = {
	translationProductDesigner: getTranslation('Ecom:Product.Pricing.Designer')
}

const variantsToShow = 3
const variantOptions = ref([])
const hasMoreVariants = ref(false)

const displayVariantOptions = () => {
	return Array.from(variantOptions.value.filter(option => option.colorHex)).slice(0, variantsToShow)
}

onMounted(() =>{
	variantOptions.value = product?.variantOptionsSimple?.variantOptionsDetails ?? []
	if (variantOptions.value.length > variantsToShow && variantOptions.value.filter(option => option.colorHex).length > 0) {
		hasMoreVariants.value = true
	}
})

const schema = ref({
	'@context': 'https://schema.org',
	'@type': 'Product',
	'name': product?.name,
	'image': product?.cylindo?.isUsingCylindoAssets ?
		product?.media?.productImage :
		'https://mobler.dk/Admin/Public/GetImage.ashx?Image=' + product?.media?.images[0].url + '&Width=280&height=220&Format=webP&Quality=90&Crop=5&resolution=50',
	'description': product?.shortDescription,
	'brand': {
		'@type': 'Brand',
		'name': product?.brand,
	},
	'offers': {
		'@type': 'Offer',
		'url': product?.link,
		'priceCurrency': 'DKK',
		'price': product?.priceAndCampaign?.price?.raw,
		'condition': 'https://schema.org/NewCondition',
	}
})

const script = document.createElement('script')
script.type = 'application/ld+json'
script.text = JSON.stringify(schema.value)
document.head.appendChild(script)
</script>

<template>
	<div class="flex flex-col relative gap-y-6 justify-center bg-white p-4 rounded
	 border border-grey-light hover:shadow hover:no-underline">
		<a class="flex justify-start align-center h-auto relative"
			:href="product?.link"
			:title="product?.name">
			<img class="object-contain w-full aspect-[4/3]"
				 v-if="product?.media?.images[0] || product?.media?.productImage"
				 :src="product?.cylindo?.isUsingCylindoAssets ?
					product?.media?.productImage :
					'/Admin/Public/GetImage.ashx?Image=' + product?.media?.images[0].url + '&Width=280&height=220&Format=webP&Quality=90&Crop=5&resolution=50'"
				 :alt="product?.name"
				 loading="lazy"
			/>
		</a>
		<Splash
			v-if="product?.priceAndCampaign?.productSplash?.visible"
			:type="product?.priceAndCampaign?.productSplash?.typeId"
		/>
		<div class="flex flex-col gap-y-2">
			<div class="flex items-center justify-between pb-2 border-grey border-b"
				v-if="variantOptions.length">
				<div class="flex items-center gap-x-2">
					<template v-for="variantOption in displayVariantOptions()">
						<a :href="variantOption.link"
						   v-if="variantOption.colorHex"
						   class="w-4 h-4 rounded-full inline-block hover:opacity-75"
						   :style="{ backgroundColor: variantOption.colorHex }"
						>
							<span class="sr-only" v-if="variantOption.color">{{ variantOption.color }}</span>
						</a>
					</template>
					<a
						v-if="hasMoreVariants"
						class="text-sm text-grey-dark hover:text-grey-dark/75"
						:href="product?.link"
						>
						<font-awesome-icon :icon="['fal', 'plus']" />
						<span class="sr-only">{{ getTranslation('GoToProduct') }}</span>
					</a>
				</div>
				<RenderAction
					v-if="product?.link"
					:data="product?.link"
					action="link"
					theme="link"
					size="none"
					class="text-sm"
					>
					<font-awesome-icon :icon="['fal', 'pen']" />
					{{ productCardTranslations.translationProductDesigner }}
				</RenderAction>
			</div>

			<div class="flex flex-col gap-y-2">
				<a class="text-black hover:text-black hover:underline"
				   :href="product?.link">
					<p class="text-base font-bold">{{ product?.name }}</p>
				</a>
				<div class="text-sm text-grey-dark *:text-sm" v-if="product?.shortDescription" v-html="product?.shortDescription"></div>
				<div class="text-sm text-grey-dark" v-if="product?.productDetailsSimple?.material"><strong>{{ getTranslation('Material') }}:</strong> <span>{{ product?.productDetailsSimple?.material }}</span></div>
				<ul class="list-none text-sm text-grey-dark flex gap-1" v-if="product?.dimensions?.depth != 0 || product?.dimensions?.height != 0 || product?.dimensions?.width != 0">
					<li v-if="product?.dimensions?.depth != 0"><strong>D/L:</strong> <span>{{ product?.dimensions?.depth }}</span></li>
					<li v-if="product?.dimensions?.height != 0"><strong>H:</strong> <span>{{ product?.dimensions?.height }}</span></li>
					<li v-if="product?.dimensions?.width != 0"><strong>B:</strong> <span>{{ product?.dimensions?.width }}</span></li>
				</ul>
			</div>
		</div>
		<div class="mt-auto">
			<PriceElement
				v-if="product?.priceAndCampaign"
				:link="product?.link"
				:has-variants="product?.hasVariant"
				theme="card"
				:price-data="product?.priceAndCampaign"
			/>
			<ul class="mt-2 list-none"
				v-if="product?.priceAndCampaign?.moreOptionsText || product?.priceAndCampaign?.variantPriceNote">
				<li class="text-xs text-grey-dark leading-2" v-if="product?.priceAndCampaign?.moreOptionsText">{{ product?.priceAndCampaign?.moreOptionsText }}</li>
				<li class="text-xs text-grey-dark" v-if="product?.priceAndCampaign?.variantPriceNote">{{ product?.priceAndCampaign?.variantPriceNote }}</li>
			</ul>
		</div>

	</div>
</template>
