<script setup lang="ts">
import {onMounted, ref, nextTick} from 'vue'
import { getTranslation } from "@/ts/utilities.ts";
import Modal from '@/components/helpers/Modal.vue'
import { GalleryData } from '@/interfaces/ProductViewInterface.ts'
import {Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

interface Props {
	data: GalleryData
}

const props = defineProps<Props>()
const gallery = ref<GalleryData>(props.data.moodImages)

const showModal = ref(false)

const gallerysplide = ref<Splide>()

const visibleImages = ref(4)
const visibleGallery = ref<GalleryData>()
const extraImages = ref(0)

const getImageClasses = (index: Number) => {
	const length = visibleGallery.value.length

	if (length === 1) {
		return 'col-span-4 row-span-2 aspect-[16/10]'
	}
	if (length === 2) {
		return 'col-span-2 row-span-2 aspect-[1/1]'
	}
	if (length === 3) {
		if (index === 0) return 'col-span-2 row-span-2' // First image spans 2x2
		if (index === 1) return 'col-span-2 row-span-1 aspect-[2/1]' // Second image spans 2x1
		return 'col-span-2 row-span-1 aspect-[2/1]' // Last image spans 2x1
	}
	if (length >= 4) {
		if (index === 0) return 'col-span-2 row-span-2'; // First image spans 2x2
		if (index === 1) return 'col-span-2 row-span-1 aspect-[2/1]'; // Second image spans 2x1
		return 'col-span-1 row-span-1 w-full'; // Last two images are 1x1
	}
}

const setGallery = () => {
	const length = gallery?.value.length

	if (length <= visibleImages.value) {
		visibleGallery.value = gallery.value
	} else {
		visibleGallery.value = gallery.value.slice(0, visibleImages.value)
	}
	extraImages.value = gallery.value.length - visibleGallery.value.length
}

const mainOptions = {
	rewind: true,
	type      : 'slide',
	perPage   : 1,
	perMove   : 1,
	gap       : '1rem',
	pagination: false,
	arrows: true,
	drag: false,
	dragAngleThreshold: 0,
	speed: 0
}

function openModal(index: number) {
	showModal.value = true

	nextTick(() => {
		changeSlide(index)
	})
}

function changeSlide(index: Number) {
	if (gallerysplide.value && gallerysplide.value.splide) {
		gallerysplide.value.splide.go(index);
	}
}

function close() {
	showModal.value = false
}

onMounted(() => {
	setGallery()

	if (gallerysplide.value && gallerysplide.value.splide) {
		gallerysplide.value.splide.on('moved', () => {
			console.log('moved')
		})

		gallerysplide.value.splide.on('mounted', () => {
			console.log('mounted')
		})
	}
})
</script>

<template>
	<div class="container mb-8">
		<div class="grid grid-cols-4 grid-flow-col grid-rows-2 gap-2 md:gap-4">
			<div
				v-for="(image, index) in visibleGallery"
				:key="index"
				class="relative hover:cursor-pointer"
				:class="getImageClasses(index)"
				@click="openModal(index)"
			>
				<picture v-if="index <= 3">
					<source
						:srcset="'/Admin/Public/GetImage.ashx?Image=' + image.url + '&Width=1600&height=1000&Format=webP&Quality=90&Crop=5&resolution=50'"
						type="image/webp"
					/>
					<img
						:src="'/Admin/Public/GetImage.ashx?Image=' + image.url + '&Width=1600&height=1000&Format=webP&Quality=90&Crop=5&resolution=50'"
						alt=""
						loading="lazy"
						class="object-cover w-full h-full rounded"
					/>
				</picture>

				<div v-if="index == 3 && extraImages" class="absolute inset-0 bg-grey-light text-black flex items-center justify-center rounded">
					<p class="text-sm text-bold md:text-4xl"><strong>{{ getTranslation('Product.Gallery.MoreImages') }}</strong></p>
				</div>
			</div>
		</div>
	</div>

	<Modal :show="showModal" @close="close()">
		<template v-slot:modal-title>
			<p class="text-xl md:text-2xl">{{ getTranslation('Product.Gallery' )}}</p>
		</template>
		<template v-slot:content>
			<Splide class="splide ml-auto"
				ref="gallerysplide"
				:options="mainOptions"
				:has-track="false"
			>
				<SplideTrack>
					<SplideSlide v-for="(image, index) in props.data.images" :key="index">
						<div class="w-full h-full flex items-center pointer-events-none js-pointer-events-toggle">
							<img class="w-full h-full max-h-[50vh] object-contain" width="800" height="600" :src="'/Admin/Public/GetImage.ashx?Image=' + image.url + '&Width=800&height=600&Format=webP&Quality=90&Crop=5&resolution=50'" alt="">
						</div>
					</SplideSlide>
				</SplideTrack>

				<div class="splide__arrows">
					<button class="splide__arrow splide__arrow--prev bg-white"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
					<button class="splide__arrow splide__arrow--next bg-white"><font-awesome-icon :icon="['fal', 'chevron-right']" /></button>
				</div>
			</Splide>
		</template>
	</Modal>
</template>

<style scoped>

</style>
